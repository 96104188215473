import React from "react";
import Header from "./Components/Header";
import { VideoSection } from "./Components/VideoSection";
import HomePageContent from "./Components/HomePageContent";
import { Box } from "@mui/material";
import bg1 from "./Assets/bg2.jpg";
import Footer from "./Components/Footer";
function App() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${bg1})`, // Correctly interpolate the variable
        backgroundSize: "cover",
        backgroundRepeat: "repeat", // This ensures the image repeats
      }}
    >
      <Header />
      <VideoSection />
      <HomePageContent />
      <Footer />
    </Box>
  );
}

export default App;
