import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';

const Footer = () => {
  return (
    <Box 
      sx={{ 
        py: 4, 

        textAlign: 'center' 
      }}
    >
      <Container>
        <Typography variant="h6" sx={{ mb: 2 }}>
          CoverUpMarkets
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          © {new Date().getFullYear()} CoverUpMarkets. All rights reserved.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
  <Button href="#" color="inherit">
    Privacy Policy
  </Button>
  <Button href="#" color="inherit">
    Terms of Service
  </Button>
  <Button href="#" color="inherit">
    Contact Us
  </Button>
</Box>
      </Container>
    </Box>
  );
};

export default Footer;
