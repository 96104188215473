import React, { useState } from "react";
import { AppBar, Toolbar, Typography, Button, IconButton, Box, useMediaQuery, Menu, MenuItem } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import logo from "../Assets/Logo.jpeg";
import PhoneIcon from "@mui/icons-material/Phone"; // Optional, if you want an icon for the button
const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = ["Home", "Features", "About Us", "Pricing"];

  return (
    <AppBar position="sticky" sx={{ background: "#FFF" }}>
      <Toolbar sx={{ justifyContent: "space-between", background: "transparent" }}>
        {/* Logo and Company Name */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img src={logo} alt="Logo" style={{ height: "40px", marginRight: theme.spacing(1) }} />
          <Typography
            variant="h5"
            component="div"
            sx={{
              background: "linear-gradient(90deg, #4682B4, #000080)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: "bold", // optional for a bolder look
            }}
          >
            CoverUpMarkets
          </Typography>
        </Box>

        {/* Navigation Tabs */}
        {isMobile ? (
          <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuClick}>
              <MenuIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              {menuItems.map((item, index) => (
                <MenuItem key={index} onClick={handleMenuClose}>
                  {item}
                </MenuItem>
              ))}
              <MenuItem onClick={handleMenuClose}>Contact</MenuItem>
            </Menu>
          </>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {menuItems.map((item, index) => (
              <Button
                key={index}
                sx={{
                  color: item === "Home" ? "#0056b3" : "#000", // Highlight Home
                  fontWeight: "600",
                  marginRight: "30px",
                  "&:hover": {
                    backgroundColor: "#f0f0f0", // Light background on hover
                    color: "#0056b3", // Change text color on hover
                  },
                }}
              >
                {item}
              </Button>
            ))}

            <IconButton
              sx={{
                background: "linear-gradient(90deg, #4682B4, #000080)",
                fontWeight: "600",
                WebkitBackgroundClip: "text", // Makes the gradient apply to the text
                WebkitTextFillColor: "transparent", // Makes the text transparent to show the gradient
              }}
            >
              <PhoneIcon sx={{ mr: 1, ml: 2 }} /> {/* Optional: Add Phone Icon */}
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 600,
                  background: "linear-gradient(90deg, #4682B4, #000080)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                9503864627
              </Typography>
            </IconButton>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
