import React from "react";
import firstpage from '../Assets/firstpage.jpg';

export const VideoSection = () => {
  return (
    <div style={{ position: "relative", height: "calc(100vh - 64px)", overflow: "hidden" }}>
      {/* Background video (commented out for now) */}
      {/* <video
        autoPlay
        muted
        loop
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1,
        }}
      >
        <source src={bgvideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}

      <div className="content-container">
        {/* Left side (Text) */}
        <div className="text-container">
          <h1 className="main-heading">
            Revolutionize Your Trading with AI and Algorithms!
          </h1>
          <p className="main-description">
            Elevate Your Trading Game with Our Cutting-Edge Algorithmic Trading and AI Screening Solutions!
          </p>

          {/* Buttons */}
          <div className="button-container">
            <button className="primary-button">Start a Free Trial</button>
            <button className="secondary-button">Schedule a Demo</button>
          </div>
        </div>

        {/* Right side (Image) */}
        <div className="image-container">
          <img
            src={firstpage}
            alt="AI-Powered Stock Trading"
            className="image"
          />
        </div>
      </div>

      {/* CSS for styling */}
      <style>
        {`
          .content-container {
            display: flex;
            height: 100vh;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
          }

          .text-container {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
          }

          .main-heading {
            font-size: 2.5rem;
            font-weight: bold;
            color: #0056b3;
            text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.8);
            animation: colorScale 6s ease-in-out infinite;
          }

          .main-description {
            font-size: 1.3rem;
            margin-bottom: 2rem;
            color: #004080;
            text-shadow: 1px 1px 5px rgba(255, 255, 255, 0.8);
            transition: transform 0.4s ease, color 0.4s ease;
          }

          .main-description:hover {
            transform: scale(1.02);
            color: #003366;
          }

          .button-container {
            display: flex;
            gap: 16px;
          }

          .primary-button, .secondary-button {
            padding: 12px 24px;
            font-size: 1rem;
            font-weight: bold;
            cursor: pointer;
            border-radius: 4px;
            transition: transform 0.4s, box-shadow 0.4s;
          }

          .primary-button {
            background: linear-gradient(90deg, #007bff, #0056b3);
            color: #fff;
            border: none;
            box-shadow: 0 4px 15px rgba(0, 123, 255, 0.5);
          }

          .primary-button:hover {
            transform: translateY(-3px);
            box-shadow: 0 8px 20px rgba(0, 123, 255, 0.7);
          }

          .secondary-button {
            background-color: transparent;
            color: #004080;
            border: 2px solid #004080;
          }

          .secondary-button:hover {
            transform: scale(1.05);
            box-shadow: 0 8px 20px rgba(0, 64, 128, 0.7);
          }

          .image-container {
            width: 50%;
            padding: 10%;
            overflow: hidden;
          }

          .image {
            width: 100%;
            height: 100%;
            border-radius: 20px;
          }

          /* Media Query for Mobile Devices */
          @media (max-width: 768px) {
            .content-container {
              flex-direction: column;
            }

            .text-container, .image-container {
              width: 100%;
            }

            .image-container {
              padding: 20px 0;
            }

            .main-heading {
              font-size: 2rem;
            }

            .main-description {
              font-size: 1rem;
            }

            .primary-button, .secondary-button {
              padding: 10px 20px;
              font-size: 0.9rem;
            }
          }

          @keyframes colorScale {
            0% {
              transform: scale(1);
              color: #0056b3;
            }
            50% {
              transform: scale(1.05);
              color: #003366;
            }
            100% {
              transform: scale(1);
              color: #0056b3;
            }
          }
        `}
      </style>
    </div>
  );
};
