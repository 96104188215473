import React from "react";
import { Box, Typography, Grid, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, CardContent } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
const StyledCard = ({ children }) => (
  <Card
    sx={{
      borderRadius: "20px",
      boxShadow: 3,
      position: "relative",
      overflow: "hidden",
      background: "#ffffff",
      color: "#0056b3",
      padding: "20px",
    }}
  >
    <CardContent>{children}</CardContent>
  </Card>
);

const SubscriptionPlanTable = () => {
  const features = [
    "Access to basic trading tools",
    "Weekly market insights",
    "Email support",
    "Access to advanced trading tools",
    "Daily market insights",
    "Personalized trading strategy sessions",
    "Priority email and chat support",
  ];

  const basicPlanFeatures = [true, true, true, false, false, false, false];
  const advancedPlanFeatures = [false, false, false, true, true, true, true];

  const prices = {
    basic: "₹499/month",
    advanced: "₹999/month",
  };

  return (
    <Container>
      <Box sx={{ py: 8, borderRadius: 2, backgroundColor: "#f9f9f9", boxShadow: 3 }}>
        <Typography variant="h4" sx={{ textAlign: "center", fontWeight: "bold", color: "#0056b3", mb: 4 }}>
          Compare Our Subscription Plans
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", color: "#0056b3", fontSize: "1.25rem" }}>Features</TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: "#0056b3", fontSize: "1.25rem" }}>
                  Basic Plan
                  <br />
                  <Typography sx={{ color: "green", fontSize: "1.5rem" }}>{prices.basic}</Typography>
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: "#0056b3", fontSize: "1.25rem" }}>
                  Advanced Plan
                  <br />
                  <Typography sx={{ color: "green", fontSize: "1.5rem" }}>{prices.advanced}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {features.map((feature, index) => (
                <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 }, "&:hover": { backgroundColor: "#e3f2fd" } }}>
                  <TableCell sx={{ fontSize: "1rem", fontWeight: "600", color: "#333" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {index > 2 ? <StarIcon sx={{ color: "#ff9800", mr: 1 }} /> : null}
                      <Typography>{feature}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center">{basicPlanFeatures[index] ? <CheckIcon sx={{ color: "#4caf50" }} /> : <CloseIcon sx={{ color: "red" }} />}</TableCell>
                  <TableCell align="center">{advancedPlanFeatures[index] ? <CheckIcon sx={{ color: "#4caf50" }} /> : <CloseIcon sx={{ color: "red" }} />}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

const HomePageContent = () => {
  return (
    <Container >
      {/* Content Sections */}
      <Box sx={{ py: 8 }}>
        <Typography variant="h4" sx={{ textAlign: "center", fontWeight: "bold", color: "#0056b3", mb: 4 }}>
          What We Offer
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <StyledCard>
              <Typography variant="h5" sx={{ fontWeight: "bold", color: "#004080" }}>
                Advanced Stock Screening Software
              </Typography>
              <Typography sx={{ mt: 2, color: "#333" }}>
                Our proprietary stock screening software scans the Indian stock market, identifying the best opportunities using real-time data, technical indicators, and trends.
              </Typography>
              <ul style={{ marginTop: "1em", paddingLeft: "20px" }}>
                <li style={{ color: "#666" }}>Custom Filters for personalized searches</li>
                <li style={{ color: "#666" }}>Real-Time Data to stay ahead</li>
                <li style={{ color: "#666" }}>Actionable insights for growth</li>
                <li style={{ color: "#666" }}>User-friendly interface for easy navigation</li>
              </ul>
            </StyledCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledCard>
              <Typography variant="h5" sx={{ fontWeight: "bold", color: "#004080" }}>
                High-Performance Trading Algorithm
              </Typography>
              <Typography sx={{ mt: 2, color: "#333" }}>
                Our algorithm is designed to generate over 20% annual returns using price action, volume analysis, and key moving averages to optimize profits.
              </Typography>
              <ul style={{ marginTop: "1em", paddingLeft: "20px" }}>
                <li style={{ color: "#666" }}>Automated trading for optimized strategies</li>
                <li style={{ color: "#666" }}>Proven 20% annual returns</li>
                <li style={{ color: "#666" }}>Robust risk management techniques</li>
                <li style={{ color: "#666" }}>Continuous improvement through backtesting</li>
              </ul>
            </StyledCard>
          </Grid>
        </Grid>
      </Box>

      {/* Indian Stock Market Insights */}
      <Box sx={{ py: 8, borderRadius: 2 }}>
        <Typography variant="h4" sx={{ textAlign: "center", fontWeight: "bold", color: "#0056b3", mb: 4 }}>
          Indian Stock Market Insights
        </Typography>
        <Typography sx={{ textAlign: "center", mb: 4, color: "#333" }}>Explore the dynamic world of the Indian stock market, including F&O swing trading and algorithmic trading benefits.</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <StyledCard>
              <Typography variant="h5" sx={{ fontWeight: "bold", color: "#004080" }}>
                F&O Swing Trading
              </Typography>
              <Typography sx={{ mt: 2, color: "#333" }}>
                Futures and Options (F&O) trading allows you to leverage your investments and hedge against market fluctuations. Our platform provides insights and strategies for effective swing
                trading, allowing you to capture short-term price movements.
              </Typography>
            </StyledCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledCard>
              <Typography variant="h5" sx={{ fontWeight: "bold", color: "#004080" }}>
                Algorithmic Trading
              </Typography>
              <Typography sx={{ mt: 2, color: "#333" }}>
                Algorithmic trading automates the trading process using predefined criteria. This results in improved execution speed, reduced transaction costs, and greater trading accuracy, making
                it an essential tool for today's traders.
              </Typography>
            </StyledCard>
          </Grid>
        </Grid>
      </Box>

      {/* Backtesting and Growth Predictions */}
      <Box sx={{ py: 8, borderRadius: 2 }}>
        <Typography variant="h4" sx={{ textAlign: "center", fontWeight: "bold", color: "#0056b3", mb: 4 }}>
          Backtesting and Growth Predictions
        </Typography>
        <Typography sx={{ textAlign: "center", mb: 4, color: "#333" }}>
          Our backtesting process allows you to evaluate trading strategies based on historical data, ensuring reliability and performance.
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <StyledCard>
              <Typography variant="h5" sx={{ fontWeight: "bold", color: "#004080" }}>
                Backtesting Results
              </Typography>
              <Typography sx={{ mt: 2, color: "#333" }}>
                Our algorithms have been backtested against a decade of market data, demonstrating consistent profitability and risk-adjusted returns. You'll receive comprehensive reports detailing
                the effectiveness of various strategies.
              </Typography>
            </StyledCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledCard>
              <Typography variant="h5" sx={{ fontWeight: "bold", color: "#004080" }}>
                Growth Predictions
              </Typography>
              <Typography sx={{ mt: 2, color: "#333" }}>
                Leverage our predictive models to anticipate market movements. Our growth predictions are based on a combination of historical trends and current market conditions, providing valuable
                insights for your trading strategy.
              </Typography>
            </StyledCard>
          </Grid>
        </Grid>
      </Box>

      <SubscriptionPlanTable />
    </Container>
  );
};

export default HomePageContent;
